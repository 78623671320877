.contact {
    .section-header {
        @extend %mb-10;
        @extend %text-left;

        .header-title {
            left: 35%;
            top: -45%;
        }
    }

    &__wrapper {
        @extend %p-rel;
        background-color: $primary-color;
        padding: 50px 20px;

        @include breakpoint(sm) {
            padding: 50px 30px;
        }

        @include breakpoint(lg) {
            padding: 60px 40px;
        }

        @include breakpoint(xl) {
            padding: 65px;
        }

        &:before,
        &:after {
            @extend %p-abs;
            @include background-cover;

        }

        &:before {
            top: 5%;
            right: 38%;
            // background-image: url(../images/contact/contact-shape1.png);
            width: 100px;
            height: 100px;
            animation-delay: 2s;
            animation: floating 5s linear infinite alternate;
        }

        &:after {
            bottom: 0;
            right: 8%;
            // background-image: url(../images/contact/contact-shape2.png);
            width: 400px;
            height: 200px;
            animation: fade 5s linear infinite alternate;
        }
    }

    &__content {
        max-width: 84%;

        >p {
            color: $white-color;
        }
    }

    &__form {
        @extend %p-rel;
        z-index: 1;

        input,
        textarea {
            @extend %w-100;
            @include add-prefix(border, 1px solid transparent);
            @include add-prefix(border-radius, 4px);
            background-color: rgba($body-color, $alpha: .70);
            color: $white-color;
            padding: 20px 30px;

            &::placeholder {
                color: $white-color;
            }

            &:focus-within {
                outline: none;
                @include add-prefix(border-color, rgba($theme-color, $alpha:.15));
            }
        }

        input {
            @extend %mb-20;
        }

        textarea {
            @extend %mb-40;
        }

        .default-btn {
            background-color: $white-color;
            color: $theme-color;

            @include hover {
                color: $theme-color;
            }
        }
    }
}

.form-massage {
    color: $white-color;
    @extend %mt-10;
}